import { NavigationLinkList } from '@doda/io-react/pc';
import {
  AREAS,
  EVENTS,
  RELATEDINFORMATIONS,
  SERVICES,
  SPECIALTIES,
} from '../../constants';
import styles from '../../index.module.scss';

export const RelationInfoContent: React.FC = () => {
  return (
    <>
      <NavigationLinkList
        className={styles.navigation_service}
        title="doda転職サービス"
        linkList={SERVICES}
      />
      <NavigationLinkList
        className={styles.navigation_specialty}
        title="専門サイト"
        linkList={SPECIALTIES}
      />
      <NavigationLinkList
        className={styles.navigation_area}
        title="地域別サイト"
        linkList={AREAS}
      />
      <NavigationLinkList
        className={styles.navigation_event}
        title="転職イベント・セミナー"
        linkList={EVENTS}
      />
      <NavigationLinkList
        className={styles.navigation_relatedInformation}
        title="関連情報"
        horizontalLink
        linkList={RELATEDINFORMATIONS}
      />
    </>
  );
};
