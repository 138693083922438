import { useEffect } from 'react';
import { normalize, tl } from '../utils/adobeAnalyticsUtil';
import { RootState } from '../redux/store';
import { AdobeOtherClickParams } from '../interfaces/app/analytics';
import {
  setAdobeAnalyticsScripts,
  setGoogleAnalyticsScripts,
} from '../redux/features/analytics';
import { useWebVitals } from './useWebVitals';
import { useAppDispatch } from '../redux/hooks';

interface Props {
  isNeedNormalize?: boolean;
}

/**
 * 計測汎用カスタムフック
 * @param isNeedNormalize - useLayoutEffectを使用した計測がある場合は、useLayoutEffect側が先に実行されるため、useLayoutEffectで初期化処理を行い、isNeedNormalizeはfalseに設定する（eventsが削除されてしまうため）
 */
export const useAnalyticsSetup = ({ isNeedNormalize = true }: Props = {}) => {
  const reportWebVitals = useWebVitals();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isNeedNormalize) normalize();
    dispatch(observeAdobeScriptDownload());
    dispatch(observeGoogleScriptDownload());
    reportWebVitals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const LOOP_LIMIT = 10;
const INTERVAL = 200;

/**
 * スクリプトが読み込まれる前で、発火処理に到達しなかったAdobe Analytics Other Clickイベントのリトライ
 * @param stackedAdobeAnalyticsClickEvent
 */
const retryStackedAdobeAnalyticsClickEvent = (
  stackedAdobeAnalyticsClickEvent: Array<AdobeOtherClickParams>
) => {
  stackedAdobeAnalyticsClickEvent.forEach(({ linkName, option }) => {
    tl(linkName, option);
    window.DD_LOGS?.logger.info(`${linkName}: tl retry`);
  });
};

/**
 * Adobe Analytics Scriptの読み込み確認処理
 */
export const observeAdobeScriptDownload =
  () => (dispatch, getState: () => RootState) => {
    const { hasAdobeAnalyticsScripts } = getState().analytics;
    if (hasAdobeAnalyticsScripts) {
      return;
    }

    let aCnt = 1;
    const intervalId = setInterval(() => {
      const { stackedAdobeAnalyticsClickEvent } = getState().analytics;

      // 終了判定
      if (hasAdobeAnalyticsScripts || aCnt === LOOP_LIMIT) {
        clearInterval(intervalId);
        return;
      }

      // 読み込み確認処理
      if (typeof window.s === 'object' && Object.keys(window.s).length) {
        dispatch(setAdobeAnalyticsScripts(true));
        retryStackedAdobeAnalyticsClickEvent(stackedAdobeAnalyticsClickEvent);
        clearInterval(intervalId);
      }

      aCnt += 1;
    }, INTERVAL);
  };

/**
 * Google Analytics Scriptの読み込み確認処理
 */
export const observeGoogleScriptDownload =
  () => (dispatch, getState: () => RootState) => {
    const { hasGoogleAnalyticsScripts } = getState().analytics;
    if (hasGoogleAnalyticsScripts) {
      return;
    }

    let aCnt = 1;
    const intervalId = setInterval(() => {
      // 終了判定
      if (hasGoogleAnalyticsScripts || aCnt === LOOP_LIMIT) {
        clearInterval(intervalId);
        return;
      }

      // 読み込み確認処理
      if (window.dataLayer) {
        dispatch(setGoogleAnalyticsScripts(true));
        clearInterval(intervalId);
      }

      aCnt += 1;
    }, INTERVAL);
  };
