export const DEFAULT_LEAD_SENTENCE =
  '転職なら、求人情報・転職サイトdoda（デューダ）' as const;

export const LONG_DODA_LEAD_SENTENCE =
  'の転職・求人なら、転職サイトdoda（デューダ）' as const;

export const MIDDLE_DODA_LEAD_SENTENCE =
  'なら、転職サイトdoda（デューダ）' as const;

export const SHORT_DODA_LEAD_SENTENCE = ' - 転職ならdoda（デューダ）' as const;

export const PUBLISH_LEAD_SENTENCE = 'の転職・求人情報を掲載中' as const;
