/** ShareContentコンポーネントのプロパティ */
interface Props {
  /** リンクリストのアイテム */
  linkList: {
    /** リンクのURL */
    href: string;
    /** 画像のURL */
    image: string;
    /** 画像のaltテキスト */
    alt: string;
  }[];
}

/**
 * ShareContentコンポーネント
 *
 * @param {Props} props - コンポーネントのプロパティ
 * @param {Object[]} props.linkList - リンクリストのアイテム
 * @param {string} props.linkList.href - リンクのURL
 * @param {string} props.linkList.image - 画像のURL
 * @param {string} props.linkList.alt - 画像のaltテキスト
 * @returns {JSX.Element} - ShareContentコンポーネント
 */
export const ShareContent: React.FC<Props> = ({ linkList }) => {
  return (
    <ul className="shareContent">
      {linkList.map((listItem, index) => {
        return (
          <li key={index}>
            <a href={listItem.href} target="_blank" rel="noreferrer">
              <img src={listItem.image} alt={listItem.alt} />
            </a>
          </li>
        );
      })}
    </ul>
  );
};
