import Encoding from 'encoding-japanese';

export const decodeKeyword = (keyword: string | undefined) => {
  if (keyword) {
    if (Encoding.detect(keyword) === 'UTF8') {
      return Encoding.convert(keyword, {
        to: 'UNICODE',
        from: 'UTF8',
      });
    }
    if (Encoding.detect(keyword) === 'SJIS') {
      return Encoding.convert(keyword, {
        to: 'UNICODE',
        from: 'SJIS',
      });
    }
  }
  return keyword;
};
