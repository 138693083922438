import { LinkList } from '../components/ExternalSiteLinks';

interface Props {
  title: string;
  href: string;
  isExternal?: boolean;
}

interface PropsLinkList {
  href: string;
  image: string;
  alt: string;
}

// ナビゲーション右上コンテンツ
export const RULES: Props[] = [
  {
    title: '利用規約',
    href: '/material/kiyaku/001.html',
  },
  {
    title: '通信に関する情報の利用について',
    href: 'https://www.persol-career.co.jp/privacy/telecom/',
  },
  {
    title: '取り扱うべき職種の範囲等に関して',
    href: 'https://www.persol-career.co.jp/jobtypes/',
  },
  {
    title: '利用者データの外部送信',
    href: 'https://www.persol-career.co.jp/terms/cookie/',
    isExternal: true,
  },
  {
    title: '免責事項',
    href: '/info/menseki/',
  },
  {
    title: 'サイトマップ',
    href: '/sitemap.html',
  },
  {
    title: 'ヘルプ',
    href: 'https://faq.doda.jp/',
  },
];

// ナビゲーションリンクリスト_doda転職サービス
export const SERVICES: Props[] = [
  {
    title: 'はじめての方へ',
    href: '/intro/',
  },
  {
    title: '求人情報検索',
    href: '/DodaFront/View/JobSearchTop/j_from_global_navi__True/',
  },
  {
    title: 'エージェントサービス',
    href: '/consultant/',
  },
  {
    title: 'スカウトサービス',
    href: '/scout/',
  },
];

// ナビゲーションリンクリスト_専門サイト
export const SPECIALTIES: Props[] = [
  {
    title: 'ITエンジニア',
    href: '/engineer/',
  },
  {
    title: 'モノづくりエンジニア',
    href: '/engineer/mono/',
  },
  {
    title: 'メディカル',
    href: '/medical/',
  },
  {
    title: 'セールス',
    href: '/sales/',
  },
  {
    title: 'ファイナンス',
    href: '/finance/',
  },
  {
    title: 'スペシャリスト',
    href: '/specialist/',
  },
  {
    title: 'エグゼクティブ',
    href: '/executive/',
  },
  {
    title: 'グローバル（アジア）',
    href: '/global/',
  },
  {
    title: 'Woman Career',
    href: '/woman/',
  },
];

// ナビゲーションリンクリスト_地域別サイト
export const AREAS: Props[] = [
  {
    title: '北海道',
    href: '/area/hokkaido/',
  },
  {
    title: '東北',
    href: '/area/tohoku/',
  },
  {
    title: '関東',
    href: '/area/kanto/',
  },
  {
    title: '東海',
    href: '/area/tokai/',
  },
  {
    title: '北信越',
    href: '/area/hokushin/',
  },
  {
    title: '関西',
    href: '/area/kansai/',
  },
  {
    title: '中国・四国',
    href: '/area/chugokushikoku/',
  },
  {
    title: '九州・沖縄',
    href: '/area/kyushu/',
  },
  {
    title: '海外',
    href: '/area/kaigai/',
  },
];

// ナビゲーションリンクリスト_転職イベント・セミナー
export const EVENTS: Props[] = [
  {
    title: '転職イベント・セミナー情報',
    href: '/event/',
  },
  {
    title: 'doda転職フェア',
    href: '/e/fair/',
  },
  {
    title: '転職セミナー動画',
    href: '/event/webmovie/',
  },
];

// ナビゲーションリンクリスト_関連情報
export const RELATEDINFORMATIONS: Props[] = [
  {
    title: '転職活動の進め方',
    href: '/guide/junbi/',
  },
  {
    title: '履歴書の書き方',
    href: '/guide/rireki/',
  },
  {
    title: '職務経歴書の書き方',
    href: '/guide/syokureki/',
  },
  {
    title: '志望動機の書き方',
    href: '/guide/rireki/douki/',
  },
  {
    title: '自己PRの書き方',
    href: '/guide/rireki/jikopr/',
  },
  {
    title: '求人応募の仕方',
    href: '/guide/oubo/',
  },
  {
    title: '面接の流れと質問',
    href: '/guide/mensetsu/',
  },
  {
    title: '内定・退職・入社の手続き',
    href: '/guide/naiteitaisyoku/',
  },
  {
    title: '第二新卒の転職活動',
    href: '/guide/nisotsu/',
  },
  {
    title: '人気企業ランキング',
    href: '/guide/popular/',
  },
  {
    title: '平均年収',
    href: '/guide/heikin/',
  },
  {
    title: '生涯年収',
    href: '/guide/heikin/lifetime_earnings/',
  },
  {
    title: '転職理由',
    href: '/guide/reason/',
  },
  {
    title: '転職年齢',
    href: '/guide/age/',
  },
  {
    title: '中途採用の実態',
    href: '/guide/saiyo/',
  },
  {
    title: '仕事満足度',
    href: '/guide/manzokudo/',
  },
  {
    title: '転職市場予測',
    href: '/guide/market/',
  },
  {
    title: 'ホンネの転職白書',
    href: '/guide/ranking/',
  },
  {
    title: '職種図鑑',
    href: '/guide/zukan/',
  },
  {
    title: '企業情報',
    href: '/companyinfo/',
  },
];

// ナビゲーションシェアコンテンツ
export const SHARECONTENTS: PropsLinkList[] = [
  {
    href: 'https://twitter.com/doda_persol',
    image: 'https://doda.jp/cmn_web/img/common/btn_foot_001.png',
    alt: 'エックスアイコン',
  },
  {
    href: 'https://www.facebook.com/doda.jp',
    image: 'https://doda.jp/cmn_web/img/common/btn_foot_002.png',
    alt: 'フェイスブックアイコン',
  },
  {
    href: 'https://b.hatena.ne.jp/entrylist?url=https%3A%2F%2Fdoda.jp%2F',
    image: 'https://doda.jp/cmn_web/img/common/btn_foot_003.png',
    alt: 'はてなブックマークアイコン',
  },
];

/**
 * デフォルトのフッター外部リンクリスト
 */
export const DEFAULT_EXTERNAL_SITE_LINK_LIST: LinkList = [
  {
    isExternal: true,
    href: 'https://www.persol-career.co.jp/',
    title: '会社案内',
  },
  {
    isExternal: true,
    href: 'https://www.persol-career.co.jp/corporate/map/',
    title: '拠点地図',
  },
  {
    isExternal: true,
    href: 'https://www.saiyo-doda.jp/lp/tm/main/?utm_medium=referral&utm_source=doda.jp&utm_campaign=doda.jp_searchresults_footer',
    title: '求人掲載・採用企業の方へ',
  },
];
