/**
 * クエリパラメーター生成
 * @param param クエリパラメーター生成用データ
 * @returns {string} クエリパラメーター
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createParam = (param: Record<string, any>) => {
  return `?${Object.entries(param)
    .map((e) => (e[1] ? `${e[0]}=${e[1]}` : null))
    .filter((i) => i !== null)
    .join('&')}`;
};

/**
 * クエリパラメーター生成 空文字許容
 * @param param クエリパラメーター生成用データ
 * @returns {string} クエリパラメーター
 */
export function createParamEmptiable(param): string {
  return `?${Object.entries(param)
    .map((e) => `${e[0]}=${e[1] ?? ''}`)
    .join('&')}`;
}
