import { useEffect } from 'react';
import { loginStatusSelector } from '../redux/features/memberInfo';
import { getCheckSession } from '../services/session/checkSessionAPI';
import { getCmsMemberInfo } from '../services/getCmsMemberInfo';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { CmsMemberInfoKey } from '../constants/cmsMemberInfoApiKey';

export const useCmsMemberInfo = async (key: CmsMemberInfoKey) => {
  const dispatch = useAppDispatch();
  const loginStatus = useAppSelector(loginStatusSelector);
  useEffect(() => {
    (async () => {
      const response = await dispatch(getCmsMemberInfo(key));
      const responseLoginStatus = response.payload?.loginStatus;
      if (loginStatus !== responseLoginStatus) {
        const isConnectedSession = await getCheckSession();
        // サーバーのセッション状態を確認し、以下の時に同期する。
        // ・サーバーのセッションは接続済み
        // ・クライアントは未ログイン状態
        if (isConnectedSession !== loginStatus) {
          window.location.reload();
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
