import { AppQueryParams } from '../constants/queryParams';
import { JobCountQuery } from '../services/getJobCountAPI';
import { createJobSearchListRequestParams } from './createJobListRequestParams';

/**
 * 求人件数取得APIリクエストパラメータを生成
 * @param {AppQueryParams} query
 * @return {JobCountQuery} 求人件数取得APIリクエストボディ
 */
export const createJobCountRequestParams = (
  query: AppQueryParams
): JobCountQuery => {
  // 検索結果一覧APIのリクエストパラメータ生成処理を呼び、不要なパラメータを排除してreturn
  const { offset, limit, sort, ...params } =
    createJobSearchListRequestParams(query);
  return params;
};
