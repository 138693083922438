import { FooterNavigation } from '@doda/io-react/pc';
import { RelationInfoContent } from '../RelationInfoContent';
import { UpperRightContent } from '../UpperRightContent';
import { ShareContent } from '../ShareContent';
import { SHARECONTENTS } from '../../constants';

export const Navigation: React.FC = () => {
  return (
    <>
      <FooterNavigation
        shareContent={<ShareContent linkList={SHARECONTENTS} />}
        upperRightContent={<UpperRightContent />}
        relationInfoContent={<RelationInfoContent />}
      />
    </>
  );
};
