import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals';
import { dataLayer } from '../utils/googleTagManagerUtil';

export const useWebVitals = () => {
  const setVariableTTFB = ({ value }) => {
    dataLayer({
      event: 'webvitals_TimetoFirstByte',
      TimetoFirstByte: Math.floor(value * 100),
    });
  };

  const setVariableFCP = ({ value }) => {
    dataLayer({
      event: 'webvitals_FirstContentfulPaint',
      FirstContentfulPaint: Math.floor(value * 100),
    });
  };

  const setVariableLCP = ({ value }) => {
    dataLayer({
      event: 'webvitals_LargestContentfulPaint',
      LargestContentfulPaint: Math.floor(value * 100),
    });
  };

  const setVariableFID = ({ value }) => {
    dataLayer({
      event: 'webvitals_FirstInputDelay',
      FirstInputDelay: Math.floor(value * 100),
    });
  };

  const setVariableCLS = ({ value }) => {
    setTimeout(() => {
      dataLayer({
        event: 'webvitals_CumulativeLayoutShift',
        CumulativeLayoutShift: Math.floor(value * 100),
      });
    }, 5000);
  };

  const setVariableINP = ({ value }) => {
    window.dataLayer = window.dataLayer || [];
    dataLayer({
      event: 'webvitals_InteractionToNextPaint',
      InteractionToNextPaint: Math.floor(value * 100),
    });
  };

  const reportWebVitals = () => {
    onTTFB(setVariableTTFB);
    onFCP(setVariableFCP);
    onLCP(setVariableLCP);
    onFID(setVariableFID);
    onCLS(setVariableCLS, {
      reportAllChanges: true,
    });
    onINP(setVariableINP, {
      reportAllChanges: true,
    });
  };
  return reportWebVitals;
};
