export const PLP_COMMA_PAGE_LIST = [
  {
    url: '/DodaFront/View/JobSearchList/j_oc__010401S/-preBtn__3/',
    commaPageName: 'IT法人営業',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__010401S%2C010402S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__010402S/-preBtn__3/',
    commaPageName: 'IT法人営業',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__010401S%2C010402S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__011201S/-preBtn__3/',
    commaPageName: '広告・メディア法人営業',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__011201S%2C011202S%2C011203S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__011202S/-preBtn__3/',
    commaPageName: '広告・メディア法人営業',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__011201S%2C011202S%2C011203S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__011203S/-preBtn__3/',
    commaPageName: '広告・メディア法人営業',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__011201S%2C011202S%2C011203S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__031306S/-preBtn__3/',
    commaPageName: 'プロジェクトマネージャー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__1402M%2C031306S%2C031410S%2C140201S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__031410S/-preBtn__3/',
    commaPageName: 'プロジェクトマネージャー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__1402M%2C031306S%2C031410S%2C140201S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__1402M/-preBtn__3/',
    commaPageName: 'プロジェクトマネージャー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__1402M%2C031306S%2C031410S%2C140201S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__140201S/-preBtn__3/',
    commaPageName: 'プロジェクトマネージャー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__1402M%2C031306S%2C031410S%2C140201S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__031411S/-preBtn__3/',
    commaPageName: 'Webサービスエンジニア',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__031411S%2C032102S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__032102S/-preBtn__3/',
    commaPageName: 'Webサービスエンジニア',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__031411S%2C032102S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0322M/-preBtn__3/',
    commaPageName: 'アプリエンジニア',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0322M%2C140305S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__140305S/-preBtn__3/',
    commaPageName: 'アプリエンジニア',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0322M%2C140305S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0429M/-preBtn__3/',
    commaPageName: 'CADオペレーター',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0429M%2C042901S%2C062209S%2C063204S%2C063305S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__042901S/-preBtn__3/',
    commaPageName: 'CADオペレーター',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0429M%2C042901S%2C062209S%2C063204S%2C063305S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062209S/-preBtn__3/',
    commaPageName: 'CADオペレーター',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0429M%2C042901S%2C062209S%2C063204S%2C063305S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__063204S/-preBtn__3/',
    commaPageName: 'CADオペレーター',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0429M%2C042901S%2C062209S%2C063204S%2C063305S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__063305S/-preBtn__3/',
    commaPageName: 'CADオペレーター',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0429M%2C042901S%2C062209S%2C063204S%2C063305S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0442M/-preBtn__3/',
    commaPageName: '生産管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0526M%2C1504M%2C044201S%2C052601S%2C120901S%2C150401S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__044201S/-preBtn__3/',
    commaPageName: '生産管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0526M%2C1504M%2C044201S%2C052601S%2C120901S%2C150401S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0526M/-preBtn__3/',
    commaPageName: '生産管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0526M%2C1504M%2C044201S%2C052601S%2C120901S%2C150401S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__052601S/-preBtn__3/',
    commaPageName: '生産管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0526M%2C1504M%2C044201S%2C052601S%2C120901S%2C150401S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__1504M/-preBtn__3/',
    commaPageName: '生産管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0526M%2C1504M%2C044201S%2C052601S%2C120901S%2C150401S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150401S/-preBtn__3/',
    commaPageName: '生産管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0526M%2C1504M%2C044201S%2C052601S%2C120901S%2C150401S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__120901S/-preBtn__3/',
    commaPageName: '生産管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0526M%2C1504M%2C044201S%2C052601S%2C120901S%2C150401S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0442M/-preBtn__3/',
    commaPageName: '工場長',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0533M%2C1511M%2C044202S%2C053301S%2C120904S%2C151101S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__044202S/-preBtn__3/',
    commaPageName: '工場長',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0533M%2C1511M%2C044202S%2C053301S%2C120904S%2C151101S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0533M/-preBtn__3/',
    commaPageName: '工場長',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0533M%2C1511M%2C044202S%2C053301S%2C120904S%2C151101S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__053301S/-preBtn__3/',
    commaPageName: '工場長',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0533M%2C1511M%2C044202S%2C053301S%2C120904S%2C151101S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__1511M/-preBtn__3/',
    commaPageName: '工場長',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0533M%2C1511M%2C044202S%2C053301S%2C120904S%2C151101S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__151101S/-preBtn__3/',
    commaPageName: '工場長',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0533M%2C1511M%2C044202S%2C053301S%2C120904S%2C151101S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__120904S/-preBtn__3/',
    commaPageName: '工場長',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0442M%2C0533M%2C1511M%2C044202S%2C053301S%2C120904S%2C151101S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062310S/-preBtn__3/',
    commaPageName: '建築施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062310S%2C062311S%2C062312S%2C062313S%2C062314S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062311S/-preBtn__3/',
    commaPageName: '建築施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062310S%2C062311S%2C062312S%2C062313S%2C062314S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062312S/-preBtn__3/',
    commaPageName: '建築施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062310S%2C062311S%2C062312S%2C062313S%2C062314S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062313S/-preBtn__3/',
    commaPageName: '建築施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062310S%2C062311S%2C062312S%2C062313S%2C062314S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062314S/-preBtn__3/',
    commaPageName: '建築施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062310S%2C062311S%2C062312S%2C062313S%2C062314S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062315S/-preBtn__3/',
    commaPageName: '設備施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062315S%2C062316S%2C062317S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062316S/-preBtn__3/',
    commaPageName: '設備施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062315S%2C062316S%2C062317S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062317S/-preBtn__3/',
    commaPageName: '設備施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062315S%2C062316S%2C062317S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062318S/-preBtn__3/',
    commaPageName: '土木施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062318S%2C062319S%2C062320S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062319S/-preBtn__3/',
    commaPageName: '土木施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062318S%2C062319S%2C062320S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062320S/-preBtn__3/',
    commaPageName: '土木施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062318S%2C062319S%2C062320S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062604S/-preBtn__3/',
    commaPageName: 'プラントプロジェクトマネジメント',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062604S%2C062605S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062605S/-preBtn__3/',
    commaPageName: 'プラントプロジェクトマネジメント',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062604S%2C062605S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062606S/-preBtn__3/',
    commaPageName: 'プラント設計',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062606S%2C062607S%2C062608S%2C062609S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062607S/-preBtn__3/',
    commaPageName: 'プラント設計',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062606S%2C062607S%2C062608S%2C062609S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062608S/-preBtn__3/',
    commaPageName: 'プラント設計',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062606S%2C062607S%2C062608S%2C062609S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062609S/-preBtn__3/',
    commaPageName: 'プラント設計',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062606S%2C062607S%2C062608S%2C062609S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062610S/-preBtn__3/',
    commaPageName: 'プラント施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062610S%2C062611S%2C062612S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062611S/-preBtn__3/',
    commaPageName: 'プラント施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062610S%2C062611S%2C062612S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062612S/-preBtn__3/',
    commaPageName: 'プラント施工管理',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062610S%2C062611S%2C062612S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062904S/-preBtn__3/',
    commaPageName: 'デューデリジェンス',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062904S%2C062905S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__062905S/-preBtn__3/',
    commaPageName: 'デューデリジェンス',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__062904S%2C062905S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__063002S/-preBtn__3/',
    commaPageName: 'プロパティマネジメント',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__063002S%2C063003S%2C063004S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__063003S/-preBtn__3/',
    commaPageName: 'プロパティマネジメント',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__063002S%2C063003S%2C063004S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__063004S/-preBtn__3/',
    commaPageName: 'プロパティマネジメント',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__063002S%2C063003S%2C063004S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__063006S/-preBtn__3/',
    commaPageName: 'マンション管理 ',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__063006S%2C063007S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__063007S/-preBtn__3/',
    commaPageName: 'マンション管理 ',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__063006S%2C063007S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0511M/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051105S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051106S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051107S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051108S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051109S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051110S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051111S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051112S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0523M/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__052301S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__1501M/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150101S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150102S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150103S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150104S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150105S/-preBtn__3/',
    commaPageName: '基礎・応用研究',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0511M%2C0523M%2C1501M%2C051105S%2C051106S%2C051107S%2C051108S%2C051109S%2C051110S%2C051111S%2C051112S%2C052301S%2C150101S%2C150102S%2C150103S%2C150104S%2C150105S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0512M/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051207S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051208S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051209S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051210S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051211S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051212S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051213S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051214S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0524M/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__052401S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__1502M/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150201S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150202S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150203S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150204S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150205S/-preBtn__3/',
    commaPageName: '製品開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0512M%2C0524M%2C1502M%2C051207S%2C051208S%2C051209S%2C051210S%2C051211S%2C051212S%2C051213S%2C051214S%2C052401S%2C150201S%2C150202S%2C150203S%2C150204S%2C150205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0514M/-preBtn__3/',
    commaPageName: '製造プロセス開発・工法開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0514M%2C0525M%2C1503M%2C051408S%2C051409S%2C051410S%2C051411S%2C051412S%2C051413S%2C052501S%2C150301S%2C150302S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051408S/-preBtn__3/',
    commaPageName: '製造プロセス開発・工法開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0514M%2C0525M%2C1503M%2C051408S%2C051409S%2C051410S%2C051411S%2C051412S%2C051413S%2C052501S%2C150301S%2C150302S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051409S/-preBtn__3/',
    commaPageName: '製造プロセス開発・工法開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0514M%2C0525M%2C1503M%2C051408S%2C051409S%2C051410S%2C051411S%2C051412S%2C051413S%2C052501S%2C150301S%2C150302S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051410S/-preBtn__3/',
    commaPageName: '製造プロセス開発・工法開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0514M%2C0525M%2C1503M%2C051408S%2C051409S%2C051410S%2C051411S%2C051412S%2C051413S%2C052501S%2C150301S%2C150302S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051411S/-preBtn__3/',
    commaPageName: '製造プロセス開発・工法開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0514M%2C0525M%2C1503M%2C051408S%2C051409S%2C051410S%2C051411S%2C051412S%2C051413S%2C052501S%2C150301S%2C150302S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051412S/-preBtn__3/',
    commaPageName: '製造プロセス開発・工法開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0514M%2C0525M%2C1503M%2C051408S%2C051409S%2C051410S%2C051411S%2C051412S%2C051413S%2C052501S%2C150301S%2C150302S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051413S/-preBtn__3/',
    commaPageName: '製造プロセス開発・工法開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0514M%2C0525M%2C1503M%2C051408S%2C051409S%2C051410S%2C051411S%2C051412S%2C051413S%2C052501S%2C150301S%2C150302S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0525M/-preBtn__3/',
    commaPageName: '製造プロセス開発・工法開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0514M%2C0525M%2C1503M%2C051408S%2C051409S%2C051410S%2C051411S%2C051412S%2C051413S%2C052501S%2C150301S%2C150302S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__052501S/-preBtn__3/',
    commaPageName: '製造プロセス開発・工法開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0514M%2C0525M%2C1503M%2C051408S%2C051409S%2C051410S%2C051411S%2C051412S%2C051413S%2C052501S%2C150301S%2C150302S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__1503M/-preBtn__3/',
    commaPageName: '製造プロセス開発・工法開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0514M%2C0525M%2C1503M%2C051408S%2C051409S%2C051410S%2C051411S%2C051412S%2C051413S%2C052501S%2C150301S%2C150302S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150301S/-preBtn__3/',
    commaPageName: '製造プロセス開発・工法開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0514M%2C0525M%2C1503M%2C051408S%2C051409S%2C051410S%2C051411S%2C051412S%2C051413S%2C052501S%2C150301S%2C150302S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150302S/-preBtn__3/',
    commaPageName: '製造プロセス開発・工法開発',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0514M%2C0525M%2C1503M%2C051408S%2C051409S%2C051410S%2C051411S%2C051412S%2C051413S%2C052501S%2C150301S%2C150302S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0518M/-preBtn__3/',
    commaPageName: '技術営業',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0518M%2C1507M%2C051802S%2C150701S%2C120801S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__051802S/-preBtn__3/',
    commaPageName: '技術営業',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0518M%2C1507M%2C051802S%2C150701S%2C120801S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__1507M/-preBtn__3/',
    commaPageName: '技術営業',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0518M%2C1507M%2C051802S%2C150701S%2C120801S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150701S/-preBtn__3/',
    commaPageName: '技術営業',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0518M%2C1507M%2C051802S%2C150701S%2C120801S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__120801S/-preBtn__3/',
    commaPageName: '技術営業',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0518M%2C1507M%2C051802S%2C150701S%2C120801S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0520M/-preBtn__3/',
    commaPageName: '製造・生産',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0520M%2C0531M%2C0532M%2C1509M%2C052001S%2C053101S%2C053102S%2C053201S%2C053202S%2C150901S%2C150902S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__052001S/-preBtn__3/',
    commaPageName: '製造・生産',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0520M%2C0531M%2C0532M%2C1509M%2C052001S%2C053101S%2C053102S%2C053201S%2C053202S%2C150901S%2C150902S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0531M/-preBtn__3/',
    commaPageName: '製造・生産',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0520M%2C0531M%2C0532M%2C1509M%2C052001S%2C053101S%2C053102S%2C053201S%2C053202S%2C150901S%2C150902S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__053101S/-preBtn__3/',
    commaPageName: '製造・生産',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0520M%2C0531M%2C0532M%2C1509M%2C052001S%2C053101S%2C053102S%2C053201S%2C053202S%2C150901S%2C150902S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__053102S/-preBtn__3/',
    commaPageName: '製造・生産',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0520M%2C0531M%2C0532M%2C1509M%2C052001S%2C053101S%2C053102S%2C053201S%2C053202S%2C150901S%2C150902S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0532M/-preBtn__3/',
    commaPageName: '製造・生産',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0520M%2C0531M%2C0532M%2C1509M%2C052001S%2C053101S%2C053102S%2C053201S%2C053202S%2C150901S%2C150902S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__053201S/-preBtn__3/',
    commaPageName: '製造・生産',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0520M%2C0531M%2C0532M%2C1509M%2C052001S%2C053101S%2C053102S%2C053201S%2C053202S%2C150901S%2C150902S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__053202S/-preBtn__3/',
    commaPageName: '製造・生産',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0520M%2C0531M%2C0532M%2C1509M%2C052001S%2C053101S%2C053102S%2C053201S%2C053202S%2C150901S%2C150902S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__1509M/-preBtn__3/',
    commaPageName: '製造・生産',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0520M%2C0531M%2C0532M%2C1509M%2C052001S%2C053101S%2C053102S%2C053201S%2C053202S%2C150901S%2C150902S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150901S/-preBtn__3/',
    commaPageName: '製造・生産',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0520M%2C0531M%2C0532M%2C1509M%2C052001S%2C053101S%2C053102S%2C053201S%2C053202S%2C150901S%2C150902S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__150902S/-preBtn__3/',
    commaPageName: '製造・生産',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0520M%2C0531M%2C0532M%2C1509M%2C052001S%2C053101S%2C053102S%2C053201S%2C053202S%2C150901S%2C150902S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__120215S/-preBtn__3/',
    commaPageName: 'メディカルドクター',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__120215S%2C120218S%2C121007S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__120218S/-preBtn__3/',
    commaPageName: 'メディカルドクター',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__120215S%2C120218S%2C121007S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__121007S/-preBtn__3/',
    commaPageName: 'メディカルドクター',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__120215S%2C120218S%2C121007S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__120702S/-preBtn__3/',
    commaPageName: '医薬品質保証',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__120702S%2C120705S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__120705S/-preBtn__3/',
    commaPageName: '医薬品質保証',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__120702S%2C120705S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__130103S/-preBtn__3/',
    commaPageName: 'クオンツ',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__130103S%2C130205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__130205S/-preBtn__3/',
    commaPageName: 'クオンツ',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__130103S%2C130205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__081101S/-preBtn__3/',
    commaPageName: 'プロデューサー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__081101S%2C081301S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__081301S/-preBtn__3/',
    commaPageName: 'プロデューサー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__081101S%2C081301S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__081102S/-preBtn__3/',
    commaPageName: 'デザイナー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0819M%2C081102S%2C081701S%2C081901S%2C082103S%2C082112S%2C082203S%2C082204S%2C082205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__081701S/-preBtn__3/',
    commaPageName: 'デザイナー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0819M%2C081102S%2C081701S%2C081901S%2C082103S%2C082112S%2C082203S%2C082204S%2C082205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__0819M/-preBtn__3/',
    commaPageName: 'デザイナー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0819M%2C081102S%2C081701S%2C081901S%2C082103S%2C082112S%2C082203S%2C082204S%2C082205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__081901S/-preBtn__3/',
    commaPageName: 'デザイナー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0819M%2C081102S%2C081701S%2C081901S%2C082103S%2C082112S%2C082203S%2C082204S%2C082205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__082103S/-preBtn__3/',
    commaPageName: 'デザイナー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0819M%2C081102S%2C081701S%2C081901S%2C082103S%2C082112S%2C082203S%2C082204S%2C082205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__082112S/-preBtn__3/',
    commaPageName: 'デザイナー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0819M%2C081102S%2C081701S%2C081901S%2C082103S%2C082112S%2C082203S%2C082204S%2C082205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__082203S/-preBtn__3/',
    commaPageName: 'デザイナー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0819M%2C081102S%2C081701S%2C081901S%2C082103S%2C082112S%2C082203S%2C082204S%2C082205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__082204S/-preBtn__3/',
    commaPageName: 'デザイナー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0819M%2C081102S%2C081701S%2C081901S%2C082103S%2C082112S%2C082203S%2C082204S%2C082205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__082205S/-preBtn__3/',
    commaPageName: 'デザイナー',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__0819M%2C081102S%2C081701S%2C081901S%2C082103S%2C082112S%2C082203S%2C082204S%2C082205S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__081112S/-preBtn__3/',
    commaPageName: 'アートディレクター',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__081112S%2C082102S%2C082202S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__082102S/-preBtn__3/',
    commaPageName: 'アートディレクター',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__081112S%2C082102S%2C082202S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__082202S/-preBtn__3/',
    commaPageName: 'アートディレクター',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__081112S%2C082102S%2C082202S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__081305S/-preBtn__3/',
    commaPageName: 'サウンドクリエイター',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__081305S%2C082208S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__082208S/-preBtn__3/',
    commaPageName: 'サウンドクリエイター',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__081305S%2C082208S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__082107S/-preBtn__3/',
    commaPageName: 'マークアップエンジニア',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__082107S%2C082206S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_oc__082206S/-preBtn__3/',
    commaPageName: 'マークアップエンジニア',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_oc__082107S%2C082206S/-preBtn__3/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__1101S/-preBtn__2/',
    commaPageName: '広告代理店',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_ind__1101S%2C1111S%2C0623S/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__1111S/-preBtn__2/',
    commaPageName: '広告代理店',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_ind__1101S%2C1111S%2C0623S/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__0623S/-preBtn__2/',
    commaPageName: '広告代理店',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_ind__1101S%2C1111S%2C0623S/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__02L/-preBtn__2/',
    commaPageName: 'メーカー業界',
    commaPageUrl: '/DodaFront/View/JobSearchList/j_ind__02L%2C03L/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__03L/-preBtn__2/',
    commaPageName: 'メーカー業界',
    commaPageUrl: '/DodaFront/View/JobSearchList/j_ind__02L%2C03L/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__0701S/-preBtn__2/',
    commaPageName: '銀行',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_ind__0701S%2C0702S%2C0703S%2C0727S/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__0702S/-preBtn__2/',
    commaPageName: '銀行',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_ind__0701S%2C0702S%2C0703S%2C0727S/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__0703S/-preBtn__2/',
    commaPageName: '銀行',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_ind__0701S%2C0702S%2C0703S%2C0727S/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__0727S/-preBtn__2/',
    commaPageName: '銀行',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_ind__0701S%2C0702S%2C0703S%2C0727S/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__0707S/-preBtn__2/',
    commaPageName: '保険業界',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_ind__0707S%2C0708S%2C0710S/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__0708S/-preBtn__2/',
    commaPageName: '保険業界',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_ind__0707S%2C0708S%2C0710S/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__0710S/-preBtn__2/',
    commaPageName: '保険業界',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_ind__0707S%2C0708S%2C0710S/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__1214S/-preBtn__2/',
    commaPageName: '専門店',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_ind__1214S%2C1215S%2C1216S/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__1215S/-preBtn__2/',
    commaPageName: '専門店',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_ind__1214S%2C1215S%2C1216S/-preBtn__2/',
  },
  {
    url: '/DodaFront/View/JobSearchList/j_ind__1216S/-preBtn__2/',
    commaPageName: '専門店',
    commaPageUrl:
      '/DodaFront/View/JobSearchList/j_ind__1214S%2C1215S%2C1216S/-preBtn__2/',
  },
];
