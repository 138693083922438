import { SavedSearchRequest } from '@doda/api-client/aspida/@types';
import { JobCountQuery } from '../services/getJobCountAPI';
import { strictEntries } from './typeUtil';
import { FacetName } from './seoUtil';
import {
  IS_NEW_ARRIVAL,
  IS_NEAR_DEDLINE,
  EMPLOYMENT_STATUS,
  HOLIDAY_AND_WAY_OF_WORKING,
  REQUIREMENTS,
  WORKPLACE_ENVIRONMENT,
  WELFARE_BENEFITS,
  LANGUAGE_SKILLS,
  POSITIONS,
  EMPLOYEE_AVERAGE_AGE,
} from '../constants/accetableRequestValues';

/**
 * OPTION
 */
const OP_MASTER = {
  isNewArrival: IS_NEW_ARRIVAL,
  isNearDeadline: IS_NEAR_DEDLINE,
  employmentStatus: EMPLOYMENT_STATUS,
  holidayAndWayOfWorking: HOLIDAY_AND_WAY_OF_WORKING,
  requirements: REQUIREMENTS,
  workplaceEnvironment: WORKPLACE_ENVIRONMENT,
  welfareBenefits: WELFARE_BENEFITS,
  languageSkills: LANGUAGE_SKILLS,
  positions: POSITIONS,
  employeeAverageAge: EMPLOYEE_AVERAGE_AGE,
};

/**
 * OPと対応する検索結果一覧 | 検索件数のクエリのkey
 */
const OP_JOB = OP_MASTER;
type OpKeys = keyof typeof OP_JOB;
type OpQuery = Pick<NonNullable<JobCountQuery>, OpKeys>;

/**
 * OPと対応する検索条件保存のクエリのkey
 */
export const OP_SAVECONDITION = {
  isNewArrival: IS_NEW_ARRIVAL,
  isNearDeadline: IS_NEAR_DEDLINE,
  employmentStatusList: EMPLOYMENT_STATUS,
  holidayAndWayOfWorkingList: HOLIDAY_AND_WAY_OF_WORKING,
  requirementsList: REQUIREMENTS,
  workplaceEnvironmentList: WORKPLACE_ENVIRONMENT,
  welfareBenefitsList: WELFARE_BENEFITS,
  languageSkillsList: LANGUAGE_SKILLS,
  positionsList: POSITIONS,
  employeeAverageAgeList: EMPLOYEE_AVERAGE_AGE,
};
type OpSaveConditionKeys = keyof typeof OP_SAVECONDITION;
type OpSaveConditionQuery = Pick<SavedSearchRequest, OpSaveConditionKeys>;

/**
 * opからrequestQueryを生成する
 * @param {String[]} opList opの配列
 * @return opから生成されるObject
 */
const opToQuery = (
  opList: string[] | undefined,
  OP: typeof OP_JOB | typeof OP_SAVECONDITION
) => {
  const query = {};

  opList?.forEach((opItem) => {
    Object.entries(OP).forEach(([key, oldOps]) => {
      const isIncludesOp = Array.isArray(oldOps)
        ? oldOps.flat().includes(opItem)
        : oldOps === opItem;
      if (!isIncludesOp) return;

      if (Array.isArray(oldOps)) {
        const opJobCountQueryValue =
          oldOps.findIndex((oldOp) => {
            return Array.isArray(oldOp)
              ? oldOp.includes(opItem)
              : oldOp === opItem;
          }) + 1;

        query[key] =
          key in query
            ? [...query[key], String(opJobCountQueryValue)]
            : [String(opJobCountQueryValue)];
      } else {
        query[key] = true;
      }
    });
  });
  return query;
};

/**
 * 検索結果一覧クエリからopを生成 opToQueryの逆
 * @param {Object} query queryObject
 * @return {string[]} - opの配列
 */
export const queryToOp = (query) => {
  const opList: string[] = [];

  if (!query) return opList;

  Object.entries(query).forEach(([key, value]) => {
    if (key in OP_MASTER) {
      // javaソースでマッピングしていたoptionの番号を取得
      const oldOps = OP_MASTER[key];

      if (Array.isArray(oldOps)) {
        (value as string[]).forEach((queryValue) => {
          // valueをstring[]と型アサーション
          const opIndex = parseInt(queryValue, 10) - 1;
          if (opIndex >= 0 && opIndex < oldOps.length) {
            const opItem = Array.isArray(oldOps[opIndex])
              ? oldOps[opIndex][0]
              : oldOps[opIndex];
            opList.push(opItem);
          }
        });
      } else if (value === true) {
        opList.push(oldOps);
      }
    }
  });

  return opList;
};

/**
 * opからjobListAPI | JobCountのパラメータを生成する
 * @param {String[]} opList opの配列
 * @return {OpQuery} opから生成されるObject
 */
export const opToJobQuery = (opList: string[] | undefined): OpQuery => {
  return opToQuery(opList, OP_JOB);
};

/**
 * opからsaveConditionAPIのパラメータを生成する
 * @param {String[]} opList opの配列
 * @return {OpSaveConditionQuery} opから生成されるObject
 */
export const opToSaveConditionQuery = (
  opList: string[] | undefined
): OpSaveConditionQuery => {
  return opToQuery(opList, OP_SAVECONDITION);
};

/**
 * opからJobCountResponsのKey/Valueを取得する
 * @param opItem
 * @returns
 */
export const opToJobCountResponsKV = (opItem: string) => {
  const { isNearDeadline, isNewArrival, ...OP_IN_JOB_COUNT_RESPONSE } =
    OP_SAVECONDITION;

  let requestQueryKey: FacetName | undefined;
  let requestQueryValue: string | undefined;

  strictEntries(OP_IN_JOB_COUNT_RESPONSE).forEach(([key, value]) => {
    const isIncludesOp = Array.isArray(value)
      ? value.flat().includes(opItem)
      : value === opItem;

    if (!isIncludesOp) return;
    requestQueryKey = key;

    requestQueryValue = String(
      value.findIndex((item) => {
        return Array.isArray(item) ? item.includes(opItem) : item === opItem;
      }) + 1
    );
  });

  if (!requestQueryKey || !requestQueryValue) return undefined;

  return { key: requestQueryKey, value: requestQueryValue };
};
