import { QUERY_PARAMS } from '../queryParams';
import {
  OP_SEARCH_ITEMS,
  HA_SEARCH_ITEMS,
  NE_SEARCH_ITEMS,
  ES_SEARCH_ITEMS,
  TP_SEARCH_ITEMS,
} from '../searchItems';

export const EMPLOYMENT_OTHER_ITEM = {
  id: [
    OP_SEARCH_ITEMS.other.id,
    OP_SEARCH_ITEMS.temporaryEmployee.id,
    OP_SEARCH_ITEMS.introductionDispatching.id,
    OP_SEARCH_ITEMS.subcontracting.id,
    OP_SEARCH_ITEMS.FCOwner.id,
  ].join(),
  name: 'その他（FCオーナー・業務委託など）',
};

export const EMPLOYMENT = {
  title: '雇用形態',
  type: QUERY_PARAMS.op,
  list: [
    OP_SEARCH_ITEMS.regularEmployee,
    OP_SEARCH_ITEMS.contractEmployee,
    EMPLOYMENT_OTHER_ITEM,
  ],
};

export const EMPLOYMENT_NOT_JOIN = {
  title: '雇用形態',
  type: QUERY_PARAMS.op,
  list: [
    OP_SEARCH_ITEMS.regularEmployee,
    OP_SEARCH_ITEMS.contractEmployee,
    OP_SEARCH_ITEMS.other,
  ],
};

export const INCOME = {
  title: '年収',
  type: QUERY_PARAMS.ha,
  list: HA_SEARCH_ITEMS,
};

export const WORKSTYLE = [
  OP_SEARCH_ITEMS.fiveDayWorkweekSystem,
  OP_SEARCH_ITEMS.MoreThan120DaysOffAYear,
  OP_SEARCH_ITEMS.holiday,
  OP_SEARCH_ITEMS.consecutiveVacations,
  OP_SEARCH_ITEMS.noOvertime,
  OP_SEARCH_ITEMS.littleOvertime,
  OP_SEARCH_ITEMS.flexibleWork,
  OP_SEARCH_ITEMS.maternityAndChildcareLeave,
];

export const OPTIONS = [
  {
    title: 'こだわり条件ピックアップ',
    type: QUERY_PARAMS.op,
    list: [
      OP_SEARCH_ITEMS.newArrival,
      OP_SEARCH_ITEMS.deadline,
      OP_SEARCH_ITEMS.fiveDayWorkweekSystem,
      OP_SEARCH_ITEMS.MoreThan120DaysOffAYear,
      OP_SEARCH_ITEMS.inexperiencedOccupationWelcome,
      OP_SEARCH_ITEMS.IndustryInexperiencedWelcome,
      OP_SEARCH_ITEMS.noAcademicBackgroundRequired,
      OP_SEARCH_ITEMS.noTransfer,
    ],
  },
  {
    title: '募集・採用情報',
    type: QUERY_PARAMS.op,
    list: [
      OP_SEARCH_ITEMS.inexperiencedOccupationWelcome,
      OP_SEARCH_ITEMS.IndustryInexperiencedWelcome,
      OP_SEARCH_ITEMS.noTransfer,
      OP_SEARCH_ITEMS.noAcademicBackgroundRequired,
      OP_SEARCH_ITEMS.secondTimeGraduate,
      OP_SEARCH_ITEMS.expert,
      OP_SEARCH_ITEMS.urgentRecruit,
      OP_SEARCH_ITEMS.employsManyPeople,
      OP_SEARCH_ITEMS.noChangeInJobTitle,
    ],
  },
  {
    title: '会社・職場の環境',
    type: QUERY_PARAMS.op,
    list: [
      OP_SEARCH_ITEMS.listedCompany,
      OP_SEARCH_ITEMS.foreignCompany,
      OP_SEARCH_ITEMS.fewerRetirees,
      OP_SEARCH_ITEMS.femaleThriving,
      OP_SEARCH_ITEMS.commutingByCar,
      OP_SEARCH_ITEMS.freeDressCode,
    ],
  },
  {
    title: '待遇・福利厚生',
    type: QUERY_PARAMS.op,
    list: [
      OP_SEARCH_ITEMS.retirementBenefitPlan,
      OP_SEARCH_ITEMS.companyHousingAndEentSubsidySystem,
      OP_SEARCH_ITEMS.childcareAndChildcareSupportSystem,
      OP_SEARCH_ITEMS.qualificationAcquisitionSupportSystem,
      OP_SEARCH_ITEMS.fullTrainingSystem,
      OP_SEARCH_ITEMS.salaryOf250kYenMore,
      OP_SEARCH_ITEMS.salaryOf350kYenMore,
      OP_SEARCH_ITEMS.UIturnSupport,
    ],
  },
  {
    title: '語学',
    type: QUERY_PARAMS.op,
    list: [OP_SEARCH_ITEMS.english, OP_SEARCH_ITEMS.chinese],
  },
  {
    title: '仕事内容',
    type: QUERY_PARAMS.op,
    list: [OP_SEARCH_ITEMS.overseasBusinessTrip, OP_SEARCH_ITEMS.manager],
  },
  {
    title: '社員の平均年齢',
    type: QUERY_PARAMS.op,
    list: [
      OP_SEARCH_ITEMS.twenties,
      OP_SEARCH_ITEMS.thirties,
      OP_SEARCH_ITEMS.forties,
      OP_SEARCH_ITEMS.overFifties,
    ],
  },
  {
    title: '従業員数',
    type: QUERY_PARAMS.ne,
    list: NE_SEARCH_ITEMS,
  },
  {
    title: '設立',
    type: QUERY_PARAMS.es,
    list: ES_SEARCH_ITEMS,
  },
  {
    title: '応募方法',
    type: QUERY_PARAMS.tp,
    list: [TP_SEARCH_ITEMS.directApplication, TP_SEARCH_ITEMS.agentServices],
  },
];

export const SEARCH_CONDITION_ITEM_TITLE = {
  OCCUPATION: '職種',
  LOCATION: '勤務地、路線・駅',
  INDUSTRY: '業種',
  SALARY: '年収',
  EMPLOYMENT: '雇用形態',
  WORKSTYLE: '休日・働き方',
  KODAWARI: 'こだわり条件ピックアップ',
  REQUIREMENT: '募集・採用情報',
  WORKPLACE_ENVIRONMENT: '会社・職場の環境',
  BENEFIT: '待遇・福利厚生',
  LANGUAGE: '語学',
  POSITION: '仕事内容',
  AVERAGE_AGE: '社員の平均年齢',
  NUMBER_OF_EMPLOYEES: '従業員数',
  ESTABLISHMENT: '設立',
  APPLICATION_METHOD: '応募方法',
  KEYWORD: 'キーワード',
};
