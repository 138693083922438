import { Methods as JobListAPI } from '@doda/api-client/aspida/api/v1/jobs';
import { AppQueryParams, QueryOtherParams } from '../constants/queryParams';
import { cassifyListAsLMS } from '../utils/convArrayUtil';
import { Modify } from '../utils/typeUtil';
import {
  ACCETABLE_INCLUDE_KEYWORD_CATEGORY_VALUES,
  ACCETABLE_EXCLUDE_KEYWORD_CATEGORY_VALUES,
  ACCETABLE_EMPLOY_NUM_VALUES,
  ACCETABLE_ESTABLISH_VALUES,
  ACCETABLE_JOB_OFFER_KIND_VALUES,
  ACCETABLE_LOWER_LIMIT_INCOME_VALUES,
  ACCETABLE_SORT_VALUES,
  ACCETABLE_UPPER_LIMIT_INCOME_VALUES,
} from '../constants/accetableRequestValues';
import { toNewQueryValue } from './createJobSearchRequestParams';
import { getIsOnePrefecture } from './onePrefectureSearch/getIsOnePrefecture';
import { opToJobQuery } from '../utils/optionQueryUtil';

const DISPLAY_NUMBER = 25;
export type JobListQuery = NonNullable<JobListAPI['get']['query']>;

/**
 * 検索結果一覧APIリクエストパラメータを生成
 * @param {AppQueryParams} query
 * @param {QueryOtherParams} queryOther
 * @return {JobListQuery} 検索結果一覧APIリクエストボディ
 */
export const createJobSearchListRequestParams = (
  query: AppQueryParams,
  queryOther?: QueryOtherParams
) => {
  // 職種を大・中・小分類に分ける
  const {
    lList: searchJobTypeLId,
    mList: searchJobTypeMId,
    sList: searchJobTypeSId,
  } = cassifyListAsLMS(query.oc);

  // 業種を大・小分類に分ける
  const { lList: industryLId, sList: industrySId } = cassifyListAsLMS(
    query.ind
  );

  // リクエストパラメータを生成
  const requestParams: Modify<JobListQuery, { offset: number }> = {
    offset: ((Number(query.page) || 1) - 1) * DISPLAY_NUMBER, // TODO: commonのため、変数である必要あり
  };
  if (query.ar) requestParams.areaId = query.ar;
  if (query.pr) requestParams.prefectureId = query.pr;
  if (query.ci) requestParams.cityId = query.ci;
  if (query.wa) requestParams.wardId = query.wa;
  if (query.rt) requestParams.routeId = query.rt;
  if (query.st) requestParams.stationId = query.st;
  if (query.oc) {
    requestParams.searchJobTypeLId = searchJobTypeLId;
    requestParams.searchJobTypeMId = searchJobTypeMId;
    requestParams.searchJobTypeSId = searchJobTypeSId;
  }
  if (query.ind) {
    requestParams.industryLId = industryLId;
    requestParams.industrySId = industrySId;
  }
  if (query.qc) requestParams.qualificationId = query.qc;
  if (query.k) {
    const includedKeyword = getKeyword(query.k);
    if (includedKeyword) {
      requestParams.includedKeyword = includedKeyword;
      requestParams.includedKeywordCategory = getIncludedKeywordCategory(
        query.kwc
      );
    }
  }
  if (query.feak) {
    const includedKeyword = getKeyword(query.feak);
    if (includedKeyword) {
      requestParams.includedKeyword = includedKeyword;
      requestParams.includedKeywordCategory = getIncludedKeywordCategory(
        query.feakwc
      );
    }
  }
  if (query.ni) {
    const excludedKeyword = getKeyword(query.ni);
    if (excludedKeyword) {
      requestParams.excludedKeyword = excludedKeyword;
      requestParams.excludedKeywordCategory = getExcludedKeywordCategory(
        query.niwc
      );
    }
  }
  if (query.feani) {
    const excludedKeyword = getKeyword(query.feani);
    if (excludedKeyword) {
      requestParams.excludedKeyword = excludedKeyword;
      requestParams.excludedKeywordCategory = getExcludedKeywordCategory(
        query.feaniwc
      );
    }
  }
  if (query.op) {
    Object.entries(opToJobQuery(query.op)).forEach(([key, value]) => {
      requestParams[key] = value;
    });
  }
  if (query.es)
    requestParams.establish = toNewQueryValue(
      query.es,
      ACCETABLE_ESTABLISH_VALUES
    ) as JobListQuery['establish'];
  if (query.ne)
    requestParams.employNum = toNewQueryValue(
      query.ne,
      ACCETABLE_EMPLOY_NUM_VALUES
    ) as JobListQuery['employNum'];
  if (query.ha) {
    requestParams.lowerLimitIncome = toNewQueryValue(
      query.ha[0],
      ACCETABLE_LOWER_LIMIT_INCOME_VALUES
    ) as JobListQuery['lowerLimitIncome'];
    requestParams.upperLimitIncome = toNewQueryValue(
      query.ha[1],
      ACCETABLE_UPPER_LIMIT_INCOME_VALUES
    ) as JobListQuery['upperLimitIncome'];
  }
  if (query.tp)
    requestParams.jobOfferKind = toNewQueryValue(
      query.tp,
      ACCETABLE_JOB_OFFER_KIND_VALUES
    ) as JobListQuery['jobOfferKind'];
  if (query.ds) {
    requestParams.sort = ACCETABLE_SORT_VALUES[query.ds];
  } else if (queryOther && queryOther.slashSortflg) requestParams.sort = '4';
  if (query.so) requestParams.limit = Number(query.so);
  if (query.pf)
    requestParams.isOnePrefecture =
      query.pf === '1' && getIsOnePrefecture(query); // 都道府県のみフラグが1でかつ、他のエリアを検索していない
  return requestParams;
};

/**
 * k,feakからIncludedKeyword、ni,feaniからexcludedKeywordを生成する
 */
const getKeyword = (keywordQuery: unknown) => {
  if (typeof keywordQuery !== 'string') return undefined;
  const trimmedText = keywordQuery.trim();
  const replacedKeyword = trimmedText.split(/\s+/g);
  return replacedKeyword;
};

/**
 * kwc,feakwcからincludedKeywordCategoryを生成する
 */
const getIncludedKeywordCategory = (includedCategoryQuery: unknown) => {
  if (typeof includedCategoryQuery !== 'string' || !includedCategoryQuery)
    return '1';
  const includedKeywordCategory = toNewQueryValue(
    includedCategoryQuery,
    ACCETABLE_INCLUDE_KEYWORD_CATEGORY_VALUES
  ) as JobListQuery['includedKeywordCategory'];
  return includedKeywordCategory;
};

/**
 * niwc,feaniwcからexcludedKeywordCategoryを生成する
 */
const getExcludedKeywordCategory = (excludedCategoryQuery: unknown) => {
  if (typeof excludedCategoryQuery !== 'string' || !excludedCategoryQuery)
    return '1';
  const excludedKeywordCategory = toNewQueryValue(
    excludedCategoryQuery,
    ACCETABLE_EXCLUDE_KEYWORD_CATEGORY_VALUES
  ) as JobListQuery['excludedKeywordCategory'];
  return excludedKeywordCategory;
};
