import { Footer as FooterPrivacyArea } from '@doda/io-react/pc';
import { FC } from 'react';
import { LinkList, FooterExternalSites } from './components/ExternalSiteLinks';
import { Navigation } from './components/Navigation';
import styles from './index.module.scss';

interface Props {
  externalSiteLinkList?: LinkList;
}

export const Footer: FC<Props> = ({ externalSiteLinkList }) => {
  return (
    <section className={styles.footer_wrapper}>
      <Navigation />
      <FooterExternalSites externalSiteLinkList={externalSiteLinkList} />
      <FooterPrivacyArea />
    </section>
  );
};
