import { HorizontalLinkList } from '@doda/io-react/pc';
import { ComponentProps, FC } from 'react';
import { DEFAULT_EXTERNAL_SITE_LINK_LIST } from 'components/Footer/constants';
import styles from '../../index.module.scss';

/**
 * Props
 */
interface Props {
  externalSiteLinkList?: LinkList;
}

/**
 * フッター外部サイトリンクリスト
 * @param externalSiteLinkList - リンクリストを上書きする場合に渡す
 */
export const FooterExternalSites: FC<Props> = ({ externalSiteLinkList }) => {
  return (
    <div className={styles.externalSites}>
      <HorizontalLinkList
        fontColor="gray"
        linkList={externalSiteLinkList || DEFAULT_EXTERNAL_SITE_LINK_LIST}
      />
    </div>
  );
};

export type LinkList = Pick<
  ComponentProps<typeof HorizontalLinkList>,
  'linkList'
>['linkList'];
