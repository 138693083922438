import { AxiosResponse } from 'axios';
import { appAxios } from '../../libs/axios/appAxios';
import { CheckSession } from '../../interfaces/api/checkSession';

/**
 * ssrサーバーのセッション状態の確認
 * @returns {boolean} セッションがつながっているか
 */
export const getCheckSession = async (): Promise<boolean> => {
  return appAxios({
    url: '/ssr_api/checkSession/',
  }).then((res: AxiosResponse<CheckSession>) => {
    return res.data.isConnected;
  });
};
