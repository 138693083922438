/**
 * HTML文字列をアンエスケープする
 * @param str
 * @returns アンエスケープされた文字列
 */
export const unescapeHtml = (str: string) => {
  const regExp = new RegExp(`${Object.keys(patterns).join('|')}`, 'g');
  return str.replace(regExp, (match) => patterns[match]);
};

// NOTE: 現状変換が必要なもののみ記載
const patterns = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
};

/**
 * HTML文字列をエスケープする
 * @param str
 * @returns エスケープされた文字列
 */
export const escapeHtml = (str: string) => {
  const regExp = new RegExp(`${Object.values(patterns).join('|')}`, 'g');
  return str.replace(
    regExp,
    (match) =>
      Object.keys(patterns).find((key) => patterns[key] === match) ?? match
  );
};
