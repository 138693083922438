import { ValueOf } from './typeUtil';
import { NotificationInfoItem } from '../interfaces/api/notificationInfo';

/**
 * ヘッター_通知情報
 */
export interface HeaderNotificationInfoType {
  // 前回取得時刻(即時取得)
  lastTimeImmediate?: string | null;
  // 前回取得時刻(12,20時取得)
  lastTimeRegMain?: string | null;
  // 前回取得時刻(8時取得)
  lastTimeRegOther?: string | null;
  // 職歴書UL促進最終表示時刻
  resumeUploadDispTime?: string | null;
  // 候補者ID
  wsfid?: string | null;
}

/**
 * 閲覧履歴_検索条件履歴
 */
export interface SearchConditionHistoryType {
  // 検索に使用されたキーワード
  keyword: string;
  // 検索が行われた日付
  searchDate: string;
}

/**
 * ビューカウント
 */
export interface ViewCount {
  // 案件／枠原稿ID
  wjoid: string;
  // ビューの数
  count: number;
  // ビューがカウントされた日付
  date: number;
}

/**
 * ローカルストレージに保存されるデータ
 */
export interface LocalStorageType {
  ['header_notificationInfo']?: HeaderNotificationInfoType;
  ['header_notificationInfoList']?: NotificationInfoItem[];
  ['searched_keyword_history_list']?: Array<SearchConditionHistoryType>;
  // アプリバナーが表示されているかどうか
  appBanner?: boolean;
  // アプリバナーの表示に関連する設定
  appBannerList?: AppBannerDisplay;
  // ビュー数のリスト
  viewCount?: Array<ViewCount>;
  // バルーンが表示されているかどうか
  isNewBalloonDisplayed?: boolean;
  isIntentMatchModalPopuped?: boolean;
}

/**
 * アプリバナー表
 */
export interface AppBannerDisplay {
  // 求人検索の詳細ページでバナーを表示するかどうかを指定するか
  jobSearchDetail?: boolean;
  // 求人検索のリストページでバナーを表示するかどうかを指定するか
  jobSearchList?: boolean;
}

/**
 * ローカルストレージを操作するユーティリティクラス
 */
export class LocalStorageUtil implements LocalStorageType {
  /**
   * setItem
   * @param key ローカルストレージのキー
   * @param value ローカルストレージに保存する値
   */
  static setItem(
    key: keyof LocalStorageType,
    value: ValueOf<LocalStorageType>
  ) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * ローカルストレージから指定されたキーのアイテムを取得
   * @param key ローカルストレージのキー
   * @returns obj ローカルストレージから取得したオブジェクト
   */
  static getItem(key: keyof LocalStorageType) {
    const data = localStorage.getItem(key);
    if (!data) return undefined;

    let obj: ValueOf<LocalStorageType>;

    try {
      obj = JSON.parse(data);
    } catch (error) {
      obj = undefined;
    }

    return obj;
  }

  /**
   * ローカルストレージから指定されたキーのアイテムを削除
   * @param key 削除するアイテムのキー
   */
  static removeItem(key: keyof LocalStorageType) {
    localStorage.removeItem(key);
  }
}
