import { AxiosResponse } from 'axios';
import { IncomingHttpHeaders } from 'http';
import { JobCountResponse } from '@doda/api-client/aspida/@types';
import { Methods } from '@doda/api-client/aspida/api/v1/jobs/count/index';
import { appAxios } from '../libs/axios/appAxios';

export type JobCountQuery = NonNullable<Methods['get']['query']>;

export const getJobCount = async (
  params?: JobCountQuery,
  headers?: IncomingHttpHeaders
) => {
  return appAxios({
    url: '/api/v1/jobs/count',
    headers,
    params,
  }).then((res: AxiosResponse<JobCountResponse>) => {
    return res.data;
  });
};
