import { SORTORDER_DODA } from '../constants/properties/arca';
import { AppQueryParams } from '../constants/queryParams';
import { decodeKeyword } from '../utils/decodeUtil';
import { existProperty } from '../utils/objectUtil';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QueryMap = Record<string, any>;

/**
 * クエリをソートし、canonical（優先順位が高い）とnormal(カノニカル以外)を返却
 * @param {QueryMap} query
 * @returns 優先順位の高いカノニカルとそうでないものを返却
 */
export const sortQuery = (
  query: QueryMap
): { canonical: QueryMap; normal: QueryMap } => {
  const canonical = {};
  const normal = { ...query };
  const canonicalKeys = SORTORDER_DODA.split(',');

  canonicalKeys.forEach((key) => {
    const value = query[key];
    if (value) {
      canonical[key] = value;
      delete normal[key];
    }
  });
  return { canonical, normal };
};

/**
 * クエリをもとに正規化URLを生成する。
 * @param {QueryMap} query
 * @param {string} path urlのpath
 * @returns
 */
export const convertQueryToStaticUrl = (query: QueryMap, path: string) => {
  // path .actionを/j_に変換.htmlを/h_に変換
  const newPath = path.replace(/.action/, '/j_').replace(/.html/, '/h_');
  // query /-[key]__[value]/か/-[key]__/[value]/に変換
  let newQuery = '';
  // /-[key]__/[value]/のパターンのkeys
  const uniqueKeys = [
    'mlow_kw',
    'mlen_kw',
    'mlen_ni',
    'kw',
    'k',
    'ni',
    'usrclk',
  ];

  Object.keys(query).forEach((key, idx) => {
    let value = query[key];
    if (value) {
      if (Array.isArray(value)) value = value.join();
      if (idx > 0) {
        newQuery += '/-';
      }
      if (uniqueKeys.includes(key)) {
        if (typeof value === 'string') value = decodeKeyword(value);
        newQuery += `${key}__/${encodeURIComponent(value)}`;
      } else {
        newQuery += `${key}__${value}`;
      }
    }
  });

  return `${newPath}${newQuery}/`;
};

/**
 * 正規化URLの作成
 * 正規化対応画面の判定処理はここではしないので、必要があれば使用箇所で行ってください
 * 処理内容
 * ・パラメータの並び替え
 * ・クエリをpathに変換
 * @param {QueryMap} query
 * @param {string} path urlのpath
 * @returns url
 */
export const makeStaticUrl = (query: QueryMap, path: string) => {
  let url = path;
  if (existProperty(query)) {
    // パラメータの並び替え
    const { canonical, normal } = sortQuery(query);

    // 正規化処理
    url = convertQueryToStaticUrl({ ...canonical, ...normal }, path);
  }
  return url;
};

/**
 * カノニカル用のクエリを抽出する
 * 不要なクエリは削除する
 * 処理内容
 * ・パラメータの並び替え,カノニカル用クエリを抽出
 * ・クエリをpathに変換
 * @param {QueryMap} query
 * @param {string} path urlのpath
 * @returns url
 */
export const extractBasicCanonicalParams = (query: QueryMap, path: string) => {
  let url = path;
  if (existProperty(query)) {
    // パラメータの並び替え
    const { canonical } = sortQuery(query);
    // 正規化処理
    url = convertQueryToStaticUrl(canonical, path);
  }
  return url;
};

/**
 * kwcがURL上存在していない場合、queryParamsをディープコピーし、kwcを削除する。
 * @param queryParams
 * @returns AppQueryParams
 */
export const removeKwcParams = (
  queryParams: AppQueryParams
): AppQueryParams => {
  const createQueryParams = { ...queryParams };
  delete createQueryParams.kwc;
  return createQueryParams;
};
